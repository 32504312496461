<script>
import { safe_exit } from '@/api/login'
import { account_balance } from "@/api/login";
import { capital, getBalance, getASBalance, getDgBalance, getUnreadCount, getThirdPartyBalance, getInfo } from "@/api/my";
import { thirdPartyBalanceFetch, thirdPartyBalanceFetchV1 } from "@/api/my";
import { caizhong, getThirdPartyList } from "@/api/home";
export default {
    data() {
        return {
            images2: "",
            username: "",
            nickname: "",

            recommend: "",
            headImage: "",

            thirdParty:[],
            unreadMsg:null,
            thirdPartyBalance:false,
            account:{

                balance:{
                    realized:null,
                    unrealized:null,
                    gameCredits:null,
                }
            },

            menuPersonalListData:[
                {
                    name:getWord('transaction_bill'),
                    link:'/my/capital_transaction/capital_transaction',
                    icon:require('@@/assets/images/personal/icons/template-3/transaction-bill.png'),
                },
                {
                    name:getWord('yulibao'),
                    link:'/my/YuLiBao',
                    icon:require('@@/assets/images/personal/icons/template-3/ylb.png'),
                    tag:'yulibao',
                },
                {
                    name:getWord('commissions'),
                    link:'/my/registration_money',
                    icon:require('@@/assets/images/personal/icons/template-3/commissions.png'),
                    tag:'commissions'
                },
                {
                    name:getWord('trade_history'),
                    link:'/my/historical_betting/historical_betting',
                    icon:require('@@/assets/images/personal/icons/template-3/drawing-history.png'),
                },
                {
                    name:getWord('promotion2'),
                    link:'/my/promote_money_making/promote_money_making',
                    icon:require('@@/assets/images/personal/icons/template-3/promotion.png'),
                    tag:'promotion'
                },
                {
                    name:getWord('regist_agent'),
                    link:'/my/agent_registration/agent_registration',
                    icon:require('@@/assets/images/personal/icons/template-3/join-us.png'),
                },
                {
                    name: getWord('red_envelope')+'/'+getWord('bonus'),
                    link:'/my/redEnvelope',
                    icon:require('@@/assets/images/personal/icons/template-3/red-envelope.png'),
                },
                {
                    name:getWord('kickback'),
                    link:'/my/return_of_members/return_of_members',
                    icon:require('@@/assets/images/personal/icons/template-3/kickback.png'),
                },
                {
                    name:getWord('settings'),
                    link:'/my/set_up/set_up',
                    icon:require('@@/assets/images/personal/icons/template-3/settings.png'),
                },
                {
                    name:getWord('about_us2'),
                    link:'/my/about/myAbout',
                    icon:require('@@/assets/images/personal/icons/template-3/about.png'),
                },
                {
                    name:getWord('site_message2'),
                    link:'/my/news/news',
                    icon:require('@@/assets/images/personal/icons/template-3/message.png'),
                },
            ],
        };
    },
    components:{
        'mt-cell':app.Mint.Cell,
    },
    methods: {
        fetchBalance(type){
            var that=this;
            function toggle(type){
                var result = that.thirdParty.filter(item=>{
                    return item.name===type
                })
                if (result.length===1) {
                    return result[0]
                }else {
                    return {}
                }
            }            
            toggle(type).fetching=true;     
            that.$forceUpdate();   
            if (type==='JdbCasino'||type==='SboCasino'||type==='SaCasino') {
                thirdPartyBalanceFetchV1(type.slice(0,type.indexOf('Casino')),).then(result=>{
                    if (result.data.code == "SUCCESS") {
                        toggle(type).fetching=false;
                        that.$forceUpdate();
                        toggle(type).balance=Number(result.data.result.money).toFixed(2)
                        app.Mint.Toast(that.getWord('balance_obtain_success'))
                    }                    
                    else {
                        toggle(type).fetching=false;
                        that.$forceUpdate();
                        app.Mint.Toast(that.getWord('balance_obtain_failure'))
                    }
                }).catch(function(error) {
                    toggle(type).fetching=false;
                    that.$forceUpdate();
                    app.Mint.Toast(that.getWord('balance_obtain_failure'))
                })
            }else {
                thirdPartyBalanceFetch( '/main/'+type+'/getMoney' ).then(result=>{
                    if (result.data.code == "SUCCESS") {
                        toggle(type).fetching=false;
                        that.$forceUpdate();
                        toggle(type).balance=Number(result.data.result.money).toFixed(2)
                        app.Mint.Toast(that.getWord('balance_obtain_success'))
                    }                    
                    else {
                        toggle(type).fetching=false;
                        that.$forceUpdate();
                        app.Mint.Toast(that.getWord('balance_obtain_failure'))
                    }
                }).catch(function(error) {
                    toggle(type).fetching=false;
                    that.$forceUpdate();
                    app.Mint.Toast(that.getWord('balance_obtain_failure'))
                })
            }
        },
        hidden(text) {
            app.Mint.Toast(text);
            return;
        },
        GetRandomNum(Min, Max) {
            var Range = Max - Min;
            var Rand = Math.random();
            return Min + Math.round(Rand * Range);
        },
        getnickname() {
            this.username = JSON.parse(localStorage.getItem("user")).username;
            this.nickname = JSON.parse(localStorage.getItem("user")).nickname;
            this.recommend = JSON.parse(localStorage.getItem("user")).recommend;
            this.headImage = JSON.parse(localStorage.getItem("user")).headImage;
        },
        signOut(){
            if (this._TEMPLATE==='template-3') {

                this.Mint.MessageBox.confirm(getWord('exit_confirm')+'?').then(action => {
                    safe_exit(JSON.parse(localStorage.getItem('user')).token).then(result => {
                        localStorage.removeItem('user');
                        this.$router.replace({ path: '/login' });
                    }).catch(err => {
                        app.Mint.Toast(err);
                        localStorage.removeItem('user');
                        this.$router.replace({ path: '/login' });
                    });
                });                
            }else {
                safe_exit(JSON.parse(localStorage.getItem('user')).token).then(result => {
                    localStorage.removeItem('user');
                    this.$router.replace({ path: '/login' });
                }).catch(err => {
                    app.Mint.Toast(err);
                    localStorage.removeItem('user');
                    this.$router.replace({ path: '/login' });
                });
            }
        },
        async getData(){
            var that=this;
            var data=null;
            var yulibaoData=null;
            if (this._userInfo && this.yulibaoConfig) {
                data=this._userInfo;
                yulibaoData=this.yulibaoConfig;
            }else { 
                data=await this.$store.dispatch('GET_userInfo',{});
                yulibaoData=await this.$store.dispatch('GET_yulibaoConfig',{});
            }
            that.grade=data.grade_name;
            that.menuPersonalListData.forEach(item=>{
                item.show=true;
            })
            if (that.menuPersonalListData.filter(item=>{
                return item.tag==='commissions'
                })[0])
            {
                if (data.config.status && data.config.tuiguanStatus) {
                    that.menuPersonalListData.filter(item=>{
                        return item.tag==='commissions'
                    })[0].show=true;
                }else {
                    that.menuPersonalListData.filter(item=>{
                        return item.tag==='commissions'
                    })[0].show=false;
                }
            }

            if (that.menuPersonalListData.filter(item=>{
                return item.tag==='promotion'
                })[0]) 
            {
                if (data.config.status && (data.config.onlineStatus || data.config.underStatus)) {
                    that.menuPersonalListData.filter(item=>{
                        return item.tag==='promotion'
                    })[0].show=true;
                }else {
                    that.menuPersonalListData.filter(item=>{
                        return item.tag==='promotion'
                    })[0].show=false;
                }
            }

            if (that.menuPersonalListData.filter(item=>{
                return item.tag==='yulibao'
                })[0])
            {
                if (yulibaoData.display_status===1) {
                    that.menuPersonalListData.filter(item=>{
                        return item.tag==='yulibao'
                    })[0].show=true;
                }else {
                    that.menuPersonalListData.filter(item=>{
                        return item.tag==='yulibao'
                    })[0].show=false;
                }
            }

            that.$forceUpdate();
        },
    },
    mounted() {
        var that=this;
        getThirdPartyList().then(_result => {
            if (_result.data.code == "SUCCESS") {
                this.thirdParty=_result.data.result
                getBalance().then(result => {
                    if (result.data.code == "SUCCESS") {
                        this.account.balance.realized = result.data.result.user_money;
                        this.thirdParty.forEach(item=>{
                            item.balance=Number(result.data.result[item.name.toLowerCase().split('casino')[0]+'_money']).toFixed(2);
                            item.fetching=false;
                        })
                    } else {
                        app.Mint.Toast(result.data.msg);
                    }
                });
            }
        })

        if (localStorage.getItem('user')) {
            this.getData();

            getUnreadCount().then(result=>{
                if (result.data.code == "SUCCESS") {
                    this.unreadMsg=result.data.result && result.data.result.unread
                }
            })
            this.$store.dispatch('GET_yulibaoConfig',{});
        }
        this.CustomScroll(this.$refs.personal.id);
        this.getnickname();
        let img2 = localStorage.getItem("login_image");
        if (!img2) {
            localStorage.setItem("login_image", this.images2);
        } else {
            this.images2 = localStorage.getItem("login_image");
        }

        capital().then(result => {
            if (result.data.code == "SUCCESS") {
                this.account.balance.gameCredits = result.data.result.score;
                this.account.balance.unrealized = result.data.result.bonus;
            } else {
                app.Mint.Toast(result.data.msg);
            }
        });
        this.$root.$emit('closeBottomTab', false)
    },
    beforeRouteLeave(to, from, next) {
        this.$root.$emit('closeBottomTab', true)
        next();
    },
    computed:{
        thirdMenu(){
            return this.$store.state.thirdMenu;
        },
        _userInfo(){
            return this.$store.state.userInfo;
        },
        yulibaoConfig(){
            return this.$store.state.yulibaoConfig;
        },
    },
};
</script>
<template>
    <section id="personal" ref="personal" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1'">
            <div class="header">
                <router-link to="/my/news/news">
                    <img class="messageIcon" src="@@/assets/images2/user/xx.png" />
                    <span v-if="unreadMsg && unreadMsg>0" class="sup">{{unreadMsg}}</span>
                </router-link>
                <img id="header_img" :src="headImage" />
                <div class="header_text">{{username}}</div>
                <div v-if="_userInfo&&_userInfo.grade_name" class="header_textid">{{ getWord('level') }}:{{_userInfo.grade_name}}</div>
                <div class="header_textid">ID：{{recommend}}</div>
            </div>
            <img class="topImg" src="@@/assets/images2/user/beijin.png" />
            <div class="moneyAndQ">
                <div class="main_text1_node">
                    <span>{{ getWord('points_of_app') }}</span>
                    <span v-if="account.balance.gameCredits===null">0</span>
                    <span v-else>{{ Number(account.balance.gameCredits).toFixed(2) }}</span>
                </div>
                <div v-if="account.balance.unrealized!==''" class="main_text1_node">
                    <span>{{ getWord('bonus') }}</span>
                    <span v-if="account.balance.unrealized===null">0</span>
                    <span v-else>{{account.balance.unrealized}}</span>
                </div>
                <div class="main_text1_node" @click="thirdPartyBalance=!thirdPartyBalance">
                    <span>{{ getWord('balance') }} <i class="arrow-down" v-show="thirdPartyBalance"></i><i class="arrow-right" v-show="!thirdPartyBalance"></i></span>
                    <span v-if="account.balance.realized===null">0</span>
                    <span v-else>{{account.balance.realized}}</span>
                </div>
            </div>
            <div class="main_text1" v-show="thirdPartyBalance">
                <template v-for="item in thirdParty">
                    <div class="main_text1_node">
                        <span>{{item.remark}}</span>
                        <span>{{item.balance}}</span>
                    </div>
                </template>
            </div>
            <div style="margin-top:-2.4rem;" class="main_text2">
                <router-link to="/my/cash_withdrawal/no_bank">
                    <mt-cell class="main_text2_mt-cell" :title="getWord('withdraw')" to is-link value>
                        <img slot="icon" src="@@/assets/images2/user/txtb.png">
                    </mt-cell>
                </router-link>
                <router-link to="/my/capitalConversion/capitalConversion">
                    <mt-cell class="main_text2_mt-cell" style="border-bottom: 0rem;" :title="getWord('transfer_client_by_others')" to is-link value>
                        <img slot="icon" src="@@/assets/images2/user/zhuanhuanerdu.png">
                    </mt-cell>
                </router-link>
                <router-link to="/my/YuLiBao" v-if="yulibaoConfig && yulibaoConfig.display_status===1">
                    <mt-cell class="main_text2_mt-cell" style="border-bottom: 0rem;" :title="getWord('yulibao')" to is-link value>
                        <img slot="icon" :src="require('@@/assets/images/personal/icons/template-3/ylb.png')">
                    </mt-cell>
                </router-link>
                <router-link to="/my/capital_transaction/capital_transaction">
                    <mt-cell class="main_text2_mt-cell" :title="getWord('transaction_bill')" to is-link value>
                        <img slot="icon" src="@@/assets/images2/user/zjjy.png">
                    </mt-cell>
                </router-link>
                <template v-if="_userInfo && _userInfo.config">
                    <router-link to="/my/registration_money" v-if="_userInfo.config.status && _userInfo.config.tuiguanStatus">
                    <mt-cell class="main_text2_mt-cell" :title="getWord('commissions')" to is-link value>
                        <img slot="icon" src="@@/assets/images2/user/zucemoney.png">
                    </mt-cell>
                </router-link>
                </template>
            </div>
            <div class="main_text2">
                <router-link to="/my/historical_betting/historical_betting">
                    <mt-cell class="main_text2_mt-cell" :title="getWord('trade_history2')" to is-link value>
                        <img slot="icon" src="@@/assets/images2/user/lstz.png">
                    </mt-cell>
                </router-link>
                <template v-if="_userInfo && _userInfo.config">
                    <router-link to="/my/promote_money_making/promote_money_making" v-if="_userInfo.config.status && (_userInfo.config.onlineStatus || _userInfo.config.underStatus)">
                    <mt-cell class="main_text2_mt-cell" :title="getWord('promotion2')" to is-link value>
                        <img slot="icon" src="@@/assets/images2/user/tgzq.png">
                    </mt-cell>
                </router-link>
                </template>
                <router-link to="/my/agent_registration/agent_registration">
                    <mt-cell class="main_text2_mt-cell" :title="getWord('regist_agent')" to is-link value>
                        <img slot="icon" src="@@/assets/images2/user/dlzc.png">
                    </mt-cell>
                </router-link>
                <router-link to="/my/return_of_members/return_of_members">
                    <mt-cell class="main_text2_mt-cell" style="border-bottom: 0rem;" :title="getWord('kickback')" to is-link value>
                        <img slot="icon" src="@@/assets/images2/user/hyfs.png">
                    </mt-cell>
                </router-link>
            </div>
            <div class="main_text2">
                <router-link to="/my/set_up/set_up">
                    <mt-cell class="main_text2_mt-cell" :title="getWord('settings')" to is-link value>
                        <img slot="icon" src="@@/assets/images2/user/sz.png">
                    </mt-cell>
                </router-link>
                <router-link to="/my/about/myAbout">
                    <mt-cell class="main_text2_mt-cell" style="border-bottom: 0rem;" :title="getWord('about_us2')" to is-link value>
                        <img slot="icon" src="@@/assets/images2/user/gy.png">
                    </mt-cell>
                </router-link>
            </div>
            <div class="shadow" v-show="thirdPartyBalance"></div>
        </template>
        <template v-if="_TEMPLATE==='template-2'">
            <div class="header">
                <router-link to="/my/news/news" class="clearfix">
                    <img class="messageIcon" :src="require('@@/assets/images/personal/icons/message.svg')" />
                    <span v-if="unreadMsg && unreadMsg>0" class="sup">{{unreadMsg}}</span>
                </router-link>

                <div class="info" v-if="_userInfo">
                    <img id="header_img" :src="headImage" />
                    <h5>
                        {{username}}
                        <span v-if="!_userInfo.talkroom_nick_name">
                            {{ _userInfo.grade_name || getWord('no_level') }}
                        </span>
                    </h5>
                    <h6 v-if="_userInfo.talkroom_nick_name">{{ getWord('nickname') }}:<span>{{ _userInfo.talkroom_nick_name }}</span></h6>
                    <h6>ID:{{recommend}}</h6>
                </div>                
            </div>
            
            <div class="moneyAndQ clearfix">
                <div class="main_text1_node">                    
                    <span v-if="account.balance.realized===null">0</span>
                    <span v-else>{{account.balance.realized}}</span>
                    <h5>QY-{{ getWord('main_account2') }}</h5>
                </div>
                <div v-if="account.balance.unrealized!==''" class="main_text1_node">                    
                    <span v-if="account.balance.unrealized===null">0</span>
                    <span v-else>{{account.balance.unrealized}}</span>
                    <h5>{{ getWord('bonus') }}</h5>
                </div>
                <div class="main_text1_node">                    
                    <span v-if="account.balance.gameCredits==null">0</span>
                    <span v-else>{{ Number(account.balance.gameCredits).toFixed(2) }}</span>
                    <h5>{{ getWord('points_of_app') }}</h5>
                </div>
                <div class="main_text1_node">
                    <a @click="thirdPartyBalance=!thirdPartyBalance">{{ getWord('account_balance') }}</a>
                    <div class="billboard" v-show="thirdPartyBalance">
                        <template v-for="item in thirdParty">
                            <div class="block" @click="fetchBalance(item.name)">
                                <b>{{item.remark}}</b>
                                <i>{{item.balance}}</i>
                                <strong class="refresh" :class="{spin:item.fetching}"></strong>
                            </div>
                        </template>
                    </div>
                </div>
            </div>

            <nav>
                <mt-cell :title="getWord('withdraw')" to="/my/cash_withdrawal/no_bank" is-link value>
                    <img slot="icon" :src="require('@@/assets/images/personal/icons/withdraw.svg')" />
                </mt-cell>
                <mt-cell :title="getWord('transfer_client_by_others')" to="/my/capitalConversion/capitalConversion" is-link value>
                    <img slot="icon" :src="require('@@/assets/images/personal/icons/exchange.svg')" />
                </mt-cell>
                <mt-cell :title="getWord('yulibao')" to="/my/YuLiBao" is-link value v-if="yulibaoConfig && yulibaoConfig.display_status===1">
                    <img slot="icon" :src="require('@@/assets/images/personal/icons/template-3/ylb.png')" />
                </mt-cell>
                <mt-cell :title="getWord('transaction_bill')" to="/my/capital_transaction/capital_transaction" is-link value>
                    <img slot="icon" :src="require('@@/assets/images/personal/icons/transaction-bill.svg')" />
                </mt-cell>
                <mt-cell :title="getWord('trade_history')" to="/my/historical_betting/historical_betting" is-link value>
                    <img slot="icon" :src="require('@@/assets/images/personal/icons/drawing-history.svg')" />
                </mt-cell>
                <template v-if="_userInfo && _userInfo.config">
                    <mt-cell :title="getWord('commissions')" to="/my/registration_money" is-link value v-if="_userInfo.config.status && _userInfo.config.tuiguanStatus">
                    <img slot="icon" :src="require('@@/assets/images/personal/icons/commissions.svg')" />
                </mt-cell>
                </template>
                <template v-if="_userInfo && _userInfo.config">
                    <mt-cell :title="getWord('promotion2')" to="/my/promote_money_making/promote_money_making" is-link value v-if="_userInfo.config.status && (_userInfo.config.onlineStatus || _userInfo.config.underStatus)">
                    <img slot="icon" :src="require('@@/assets/images/personal/icons/promotion.svg')" />
                </mt-cell>
                </template>
                <mt-cell :title="getWord('red_envelope')+'/'+getWord('bonus')" to="/my/redEnvelope" is-link value>
                    <img slot="icon" :src="require('@@/assets/images/personal/icons/red-envelope.png')" />
                </mt-cell>
                <mt-cell :title="getWord('regist_agent')" to="/my/agent_registration/agent_registration" is-link value>
                    <img slot="icon" :src="require('@@/assets/images/personal/icons/join-us.svg')" />
                </mt-cell>
                <mt-cell :title="getWord('kickback')" to="/my/return_of_members/return_of_members" is-link value>
                    <img slot="icon" :src="require('@@/assets/images/personal/icons/kickback.svg')" />
                </mt-cell>

                <mt-cell :title="getWord('settings')" to="/my/set_up/set_up" is-link value>
                    <img slot="icon" :src="require('@@/assets/images/personal/icons/settings.svg')" />
                </mt-cell>
                <mt-cell :title="getWord('about_us2')" to="/my/about/myAbout" is-link value>
                    <img slot="icon" :src="require('@@/assets/images/personal/icons/about.svg')" />
                </mt-cell>
            </nav>

            <div class="shadow" @click="thirdPartyBalance=!thirdPartyBalance" v-show="thirdPartyBalance"></div>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <header>
                <a class="sign-out" @click="signOut">{{ getWord('exit') }}<i class="iconfont icon-back"></i></a>

                <img id="avatar" :src="headImage" />
                <div class="info" v-if="_userInfo">                    
                    <h5>{{ _userInfo.nickname }}
                        <span v-if="_userInfo.grade_name">{{ _userInfo.grade_name }}</span>
                    </h5>
                    <h6 v-if="_userInfo.talkroom_nick_name">{{ getWord('nickname') }}：{{ _userInfo.talkroom_nick_name }}</h6>
                    <h6>ID:{{ _userInfo.recommend }}</h6>
                </div>                
            </header>
            <div class="account-overview">                
                <span>{{ getWord('balance') }}：{{account.balance.realized}}</span>
                <span>{{ getWord('bonus') }}：{{account.balance.unrealized}}</span>
                <span>{{ getWord('points_of_app') }}：{{account.balance.gameCredits}}</span>
            </div>
            <ul class="menu-account">
                <li>
                    <router-link to="/money">
                        <img :src="require('@@/assets/images/personal/icons/template-3/recharge.png')" />{{ getWord('recharge') }}
                    </router-link>
                </li>
                <li>
                    <router-link to="/my/cash_withdrawal/no_bank">
                        <img :src="require('@@/assets/images/personal/icons/template-3/withdraw.png')" />{{ getWord('withdraw2') }}
                    </router-link>
                </li>
                <li v-if="thirdParty.length>0">
                    <router-link to="/my/capitalConversion/capitalConversion">
                        <img :src="require('@@/assets/images/personal/icons/template-3/exchange.png')" />{{ getWord('transfer_client_by_others') }}
                    </router-link>
                </li>
            </ul>
            <div class="menu-personal">
                <mt-cell v-for="(item,index) in menuPersonalListData" :key="item.name" :title="item.name" :to="item.link" is-link value v-show="item.show">
                    <img slot="icon" :src="item.icon" />
                    <template v-if="item.name===getWord('site_message2')">
                        <span v-if="unreadMsg && unreadMsg>0" class="sup">{{unreadMsg}}</span>
                    </template>
                </mt-cell>
            </div>
        </template>
    </section>
</template>

<style lang="scss" scoped>
@import "@@/assets/style/_variables";
#personal {
    overflow: auto;

    &.template-2 {
        background-color: #F5F5F9;

        .shadow {
            top: 0;
            z-index: 2;
            background-color: rgba(0,0,0,.7);
        }

        .header {
            position: static;
            float: none;
            height: auto;
            background: rgb(71,120,227);
            background: linear-gradient(90deg, rgba(71,120,227,1) 0%, rgba(139,185,241,1) 100%);
            padding: .3rem;
            box-sizing: border-box;
            color: #ffffff;

            a {
                display: inline-block;
                position: relative;
                float: right;
                clear: both;

                .messageIcon {
                    float: none;
                }
            }

            .info {
                padding: .6rem 0 1.2rem 0;

                #header_img {                
                    margin: 0;
                    height: 1.2rem;
                    border-radius: 100%;
                    margin-right: .25rem;
                }

                h5 {
                    font-weight: normal;
                    font-size: .48rem;

                    span {
                        font-size: .24rem;
                        vertical-align: middle;
                        background: #ffffff;
                        color: #82B7F8;
                        padding: .05rem .1rem;
                    }
                }

                h6 {
                    font-weight: normal;
                    font-size: .28rem;
                    color: #ffffff;
                    padding: .05rem .1rem;

                    span {
                        background-color: #ffffff;
                        color: $TEMPLATE2-theme-color;
                        margin-left: .1rem;
                        padding: 0 .1rem;
                    }
                }
            }
        }

        .moneyAndQ {
            top: 0;
            left: 0;
            width: auto;
            margin: 0 .25rem;
            background-color: #ffffff;
            margin-top: -1rem;

            .main_text1_node {
                margin-bottom: 0.3rem;
                width: 24%;
                font-size: .3rem;
                border-left: 1px dotted #F5F5F9;
                box-sizing: border-box;
                position: relative;

                .billboard {
                    width: 95vw;
                    right: 0;
                    top: 1rem;
                    position: absolute;
                    z-index: 3;
                    background-color: #ffffff;
                    display: flex;
                    flex-wrap: wrap;
                    border-radius: .1rem;
                    padding-bottom: .2rem;

                    &:before {
                        content: "";
                        width: 14px;
                        height: 14px;
                        transform: rotate(-45deg);
                        background: #fff;
                        position: absolute;
                        /*box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.5);*/
                        z-index: -1;
                        top: -6px;
                        left: calc(90% - 10px);
                    }

                    .block {
                        width: 25%;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        height: 1.4rem;
                        padding-top: .25rem;
                        box-sizing: border-box;
                        position: relative;

                        i {
                            font-style: normal;
                        }

                        b,strong {
                            font-weight: normal;
                        }

                        b {
                            margin-bottom: .1rem;
                            white-space: nowrap;
                        }
                    }
                }

                &:first-child {
                    width: 28%;
                }

                a {
                    color: $TEMPLATE2-theme-color;
                    line-height: .9rem;
                }

                span {
                    font-size: .32rem;
                    color: #252525;
                    float: none;
                }

                h5 {
                    font-weight: normal;
                    color: #999999;
                    margin-top: .1rem;
                    font-size: .3rem;
                }
            }
        }

        nav {
            padding: .25rem 0 .5rem 0;
            
            a {
                min-height: .8rem;
                border-top: 1px solid #f5f5f9;

                &:first-child {
                    border-top: 0;
                }

                &:nth-child(5),
                &:nth-child(9) {
                    margin-bottom: .25rem;
                }

                /deep/ .mint-cell-wrapper {                    

                    .mint-cell-title {

                        span {
                            font-size: .3rem;
                        }

                        img {
                            margin-right: 0.3rem;
                            width: 0.42rem;
                            height: 0.42rem;
                        }
                    }        

                    .mint-cell-allow-right {

                        &:after {
                            width: 8px;
                            height: 8px;
                        }
                    }
                }            
            }
        }

        .refresh {
            bottom: 0.05rem;
        }
    }

    &.template-3 {
        height: 100%;
        background-color: #EEEEEE;

        header {
            background-color: #EC2829;
            display: flex;
            padding: .15rem;
            align-items: center;
            position: relative;

            .sign-out {
                color: #ffffff;
                font-size: .3rem;
                position: absolute;
                right: .25rem;
                top:.15rem;

                i {
                    display: inline-block;
                    transform: rotateZ(180deg);
                    vertical-align: text-top;
                }
            }

            #avatar {
                width: 1.28rem;
                max-height: 1.28rem;
                margin-right: .25rem;
            }

            .info {
                color: #FFFFFF;              

                h5 {
                    font-size: .36rem;
                    font-weight: normal;

                    span {
                    font-size: .24rem;
                    font-weight: normal;
                    background-color: #ffffff;
                    color: #EC2829;
                        padding: .05rem .25rem;
                    display: inline-block;
                        border-radius: 3px;
                        position: relative;
                        top: -2px;
                    }
                }

                h6 {
                    font-size: .28rem;
                    font-weight: normal;

                    &:last-child {
                        background-color:transparent;
                        color: #ffffff;
                        font-size: .28rem;
                        display: block;
                    }
                }
            }
        }

        .menu-account {
            display: flex;

            li {
                list-style-type: none;
                flex-basis: 50%;
                background-color: #C00004;
                font-size: .36rem;
                display: flex;
                justify-content: center;
                padding: .15rem 0;
                border-left: 1px solid #870003;

                a {
                    color: #ffffff;
                    display: flex;
                    align-items: center;
                }

                &:first-child {
                    border-left:0;
                }

                img {
                    width: .36rem;
                    margin-right: .15rem;
                }
            }
        }

        .menu-personal {

            a {
                padding: 0 .5rem;

                &:first-child {

                    /deep/ .mint-cell-wrapper {
                        border-top:0;
                    }
                }

                &:last-child {
                    margin-top: .25rem;

                    /deep/ .mint-cell-wrapper {
                        border-top: 0;
                    }
                }

                /deep/ .mint-cell-wrapper {
                    position: relative;
                    padding: 0.25rem 0;
                    border-top:1px solid #B0B0B0;
                    background-image: none;

                    .mint-cell-title {

                        img {
                            width: .4rem;
                            max-height: .4rem;
                            margin-right: .1rem;
                        }

                        .mint-cell-text {
                            font-size: .3rem;
                        }
                    }

                    .mint-cell-value {

                        .sup {
                            font-size: .24rem;
                            background-color: #EC2829;
                            padding: .1rem;
                            color: #ffffff;
                            border-radius: 100%;
                            min-width: .24rem;
                            text-align: center;
                        }
                    }

                    .mint-cell-allow-right {

                        &:after {
                            width:8px;
                            height: 8px;
                            border-right-width: 1px;
                            border-top-width: 1px;
                            right: 4px;
                        }
                    }
                }
            }
        }

        .account-overview {
            font-size: .28rem;
            color: #ffffff;
            border-top:1px solid #C00004;
            background-color: #EC2829;
            display: flex;
            justify-content: space-around;
            padding: .15rem 0;
        }
    }

    &.template-1 {

        .moneyAndQ {
            position: relative;
            z-index: 20;    
        }
    }

    .header {
        float: left;
        height: 1.43rem;
        width: 100%;
        position: relative;
        top: 0.6rem;
        z-index: 9;

        .messageIcon {
            float: right;
            height: 0.4rem;
            margin-right: 0.3rem;
        }

        .sup {
            position: absolute;
            right: .1rem;
            background: red;
            color: white;
            border-radius: 100%;
            width: .35rem;
            text-align: center;
            height: .35rem;
            line-height: .35rem;
            top: -.175rem;
        }

        #header_img {
            float: left;
            margin-left: 0.2rem;
            height: 1.43rem;
            border-radius: 5rem;
        }

        .header_text {
            float: left;
            width: 3.08rem;
            height: 0.35rem;
            font-size: 0.35rem;
            margin-left: 0.2rem;
            color: #FFF;
        }

        .header_textid {
            float: left;
            display: block;
            width: 50%;
            height: 0.18rem;
            font-size: 0.18rem;
            color: #FFF;
            margin: 0.2rem 0 0 0.2rem;
        }
    }

    .main_text1 {        
        float: left;
        width: 7.2rem;
        background-color: white;
        margin: 0.1rem 0 0 0.15rem;
        border-radius: 0.1rem;
        box-shadow: 0 0 0.2rem #888;
        position: relative;
        z-index: 9;
        top: 3.6rem;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: absolute;

        .main_text1_node {
            float: left;
            width: 25%;
            height: 1.5rem;
            text-align: center;            
            padding-top: 0.3rem;
            box-sizing: border-box;
            border-left: 1px solid #d9d9d9;

            &:first-child {
                border-left:0;
            }

            &:nth-child(4n+1) {
                border-left: 0;
            }

            &:last-child {
                border-right: 1px solid #d9d9d9;
            }

            &:nth-child(1n+5) {
                border-top: 1px solid #d9d9d9;
            }

            span {
                color: #000;
                float: left;
                margin-top: 0.1rem;
                width: 100%;
                font-size: 0.24rem;

                &:last-child {
                    color: #999;
                }
            }
        }
    }

    .main_text2 {
        float: left;
        width: 7.2rem;
        border-radius: 0.1rem;
        margin: 0.2rem 0 0 0.15rem;
        background-color: white;
        box-shadow: 0 0 0.05rem #ccc;

        .main_text2_mt-cell {
            float: left;
            width: 95%;
            border-bottom: 1px solid rgba(187, 187, 187, 1);
            margin-left: 2.5%;

            img {
                float: left;
                margin-right: 0.3rem;
                width: 0.42rem;
                height: 0.42rem;
                background-color: white;
            }
        }
    }

    .moneyAndQ {
        width: 7.2rem;        
        top: -3.1rem;
        left: 0.15rem;

        .main_text1_node {
            float: left;
            width: 33.33%;
            height: 100%;
            text-align: center;
            margin-top: 0.3rem;

            span {
                color: #FFFFFF;
                float: left;
                margin-top: 0.1rem;
                width: 100%;
                font-size: 0.24rem;

                &:last-child {
                    color: #FFFFFF;
                }
            }
        }
    }

    .arrow-down {
        width: 0; 
        height: 0; 
        border-left: .125rem solid transparent;
        border-right: .125rem solid transparent;      
        border-top: .15rem solid #fff;
        display: inline-block;
    }
    .arrow-right {
        width: 0; 
        height: 0; 
        border-top: .125rem solid transparent;
        border-bottom: .125rem solid transparent;
        border-left: .15rem solid #fff;
        display: inline-block;
        vertical-align: -1px;
    }

    .topImg {
        width: 100%;
        z-index: 1;
        position: relative;
        top: -1.43rem;
    }

    .shadow {
        position: absolute;
        background-color: rgba(0,0,0,.2);
        width: 100%;
        height: 100%;
        top: 1rem;
    }

    .refresh {
        width: .22rem;
        height: .22rem;
        display: inline-block;
        position: absolute;
        right: .1rem;
        bottom: .1rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='22' height='23' viewBox='0 0 22 23'%3E %3Cg transform='scale(.5)' fill='%233985E1'%3E %3Cpath d='M-870.564,1088.811a8.16,8.16,0,0,1-3.644-6.774,8.169,8.169,0,0,1,5.583-7.693l-1.763-2.239a10.862,10.862,0,0,0-6.582,9.989,10.872,10.872,0,0,0,5.289,9.3l-.882,2.009,6.994-.861-3.82-6.258Zm9.579-16.419,1.058-1.952-7.64.746,4.29,5.454,1-1.837a8.128,8.128,0,0,1,4.525,7.291,8.142,8.142,0,0,1-6.641,7.98v.172l1.7,2.181a10.872,10.872,0,0,0,7.7-10.333,10.959,10.959,0,0,0-5.995-9.7Zm0,0' transform='translate(876.966 -1070.436)' /%3E %3C/g%3E %3C/svg%3E");
        background-size: 200% 200%;
        
        &.spin {
            animation: 2s linear infinite normal refresh-spin;
        }
    }

    @keyframes refresh-spin { from { transform: rotate(0deg); } to { transform: rotate(360deg); }  }
}
    
</style>